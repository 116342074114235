<template>
    <div>
        <button
            type="button"
            class="relative block p-3 text-center truncate"
            :class="{
                'w-24': !size,
                'w-full': size === 'auto'
            }"
            @click="openMedia(media)"
        >
            <span class="mx-auto block rounded opacity-75">
                <img
                    :src="sourceThumbnail"
                    alt="Thumbnail"
                    class="object-contain"
                    :class="{
                        'w-24 h-10': !size,
                        'w-full max-h-96': size === 'auto'
                    }"
                    loading="lazy"
                >
            </span>
            <span class="mt-1 text-xs block text-gray-500 truncate">
                {{ media.name }}
            </span>
        </button>
        <UiModal
            :id="`media-preview-${media.id}`"
            no-background
            size="6xl"
        >
            <div v-if="carousel">
                <carousel
                    v-model="currentSlide"
                    :items-to-show="1"
                >
                    <slide
                        v-for="mediaSlide in carousel"
                        :key="mediaSlide.id"
                    >
                        <img
                            :src="mediaSlide.url"
                            class="mx-auto max-h-[80vh]"
                            :alt="mediaSlide.name"
                        >
                    </slide>
                    <template #addons>
                        <navigation />
                    </template>
                </carousel>
            </div>
            <img
                v-else
                :src="media.url"
                class="mx-auto max-h-[80vh]"
                :alt="media.name"
            >
        </UiModal>
    </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
    components: {
        Carousel,
        Slide,
        Navigation
    },
    props: {
        size: {
            type: String,
            default: null
        },
        media: {
            type: Object,
            required: true
        },
        carousel: {
            type: Array,
            default: null
        }
    },

    data () {
        return {
            source: null,
            currentSlide: 0
        }
    },

    computed: {
        sourceThumbnail () {
            if (this.media.mime_type.includes('application/pdf')) {
                return 'https://abcreche.com/img/icons/001-pdf.png'
            }

            if (this.media.mime_type.includes('zip')) {
                return 'https://abcreche.com/img/icons/003-zip.png'
            }

            if (this.media.mime_type.includes('word')) {
                return 'https://abcreche.com/img/icons/005-word.png'
            }

            if (this.media.mime_type.includes('spreadsheet')) {
                return 'https://abcreche.com/img/icons/004-xls.png'
            }

            if (this.media.mime_type.includes('video')) {
                return 'https://abcreche.com/img/icons/005-mov.png'
            }

            return this.media.thumb_url || this.media.url
        }
    },

    created () {
        if (this.carousel) {
            this.currentSlide = this.carousel.findIndex(slide => slide.id === this.media.id)
        }
    },

    mounted () {
        // listen to arrow left and right
        window.addEventListener('keydown', this.handleKeydown)
    },

    unmounted () {
        window.removeEventListener('keydown', this.handleKeydown)
    },

    methods: {
        openMedia () {
            const imageUrl = this.media.thumb_url || this.media.url
            if (this.sourceThumbnail === imageUrl) {
                this.$modal.show('media-preview-' + this.media.id)
            } else {
                window.open(this.media.url, '_blank')
            }
        },

        handleKeydown (key) {
            if (this.carousel) {
                // if left arrow then go to previous slide
                if (key.code === 'ArrowLeft') {
                    this.currentSlide = this.currentSlide === 0 ? this.carousel.length - 1 : this.currentSlide - 1
                }
                // if right arrow then go to next slide
                if (key.code === 'ArrowRight') {
                    this.currentSlide = this.currentSlide === this.carousel.length - 1 ? 0 : this.currentSlide + 1
                }
            }
        }
    }
}
</script>
